@import "./normalize.css";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html, body {
    height: 100%;
}

#root {
    height: 100%;
}

:root {
    --black: #202945;
    --blue: #6573FF;
    --gray: #DEE0F7;
    --light-gray: #FBFBFD;
    --white: #FFFFFF;
    --dark-gray: #7F869F;
    --background: #FAFAFF;
    --href: #5B627A;
    --dark-blue: #2E39B3;
    --padding-btn: #F1F3FF;
    --green: #8CC63E;
    --green-background: rgba(118, 158, 42, 0.10);
    --ads-background: #444E71;
    --sh-header-bg: #0F2C52;
    --ads-gray: #A1A1A1;
}

* {
    font-family: 'Inter', sans-serif !important;
}

a {
    text-decoration: none !important;
}

img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
